import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IonPage, IonContent } from '@ionic/react';

function ReviewSuccessPage() {
    const { type, id } = useParams();
    const { t } = useTranslation();

    return (
        <IonPage>
            <IonContent>
                <section id="success-page" className="relative pt-8 pb-32 font-sans text-center">
                    <p className="font-bold text-xl">Valuta ristorante</p>

                    <img src="/assets/images/review_complete.png" className="w-1/2 m-auto pt-12 pb-10" alt="checkout success" />

                    <div className="my-4 px-8">
                        <h2 className="font-bold text-xl">Il tuo voto è stato registrato!</h2>
                        <p className="text-sm">Grazie per contribuire a migliorare l’esperienza della community di MyCIA.</p>
                    </div>

                    <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                        <a href={`/${type}/q/${id}/menu`} className="block py-3 rounded-full text-white bg-mycia-blue">{t("successpage.button_1")}</a>
                    </div>

                </section>

            </IonContent>
        </IonPage>
    );
}

export default ReviewSuccessPage;

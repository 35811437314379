function getThemeIonComponents(theme) {
    if (theme === "cocacola") {
        return {
            splashPage: { "--background": "#e6e6e5" },
            menupage: { "--background": "#e6e6e5" },
        };
    } else if (theme === "morgana") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    } else if (theme === "sushitao") {
        return {
            splashPage: { "--background": "#0C2835" },
            menupage: { "--background": "#0C2835" },
        };
    } else if (theme === "redcafe") {
        return {
            splashPage: { "--background": "#EEE4D2" },
            menupage: { "--background": "#EEE4D2" },
        };
    } else if (theme === "motueka") {
        return {
            splashPage: { "--background": "black" },
            menupage: { "--background": "black" },
        };
    } else if (theme === "camparino") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    } else if (theme === "voglia_argentina") {
        return {
            splashPage: { "--background": "#FFFFFF" },
            menupage: { "--background": "#FFFFFF" },
        };
    } else if (theme === "pokeisland") {
        return {
            splashPage: { "--background": "#ECA8AA" },
            menupage: { "--background": "#ECA8AA" },
        };
    } else if (theme === "ten") {
        return {
            splashPage: { "--background": "#535353" },
            menupage: { "--background": "#535353" },
        };
    } else if (theme === "montaditos") {
        return {
            splashPage: { "--background": "#F5EFE4" },
            menupage: { "--background": "#FFFFFF" },
        };
    } else if (theme === "barriobotanico") {
        return {
            splashPage: { "--background": "url(/assets/images/barriobotanico.png)" },
        };
    } else if (theme === "vanquish") {
        return {
            splashPage: { "--background": "url(/assets/images/vanquish.png)" },
        };
    } else if (theme === "letorri") {
        return {
            splashPage: { "--background": "black" },
            menupage: { "--background": "black" },
        };
    } else if (theme === "fellini") {
        return {
            splashPage: { "--background": "url(/assets/images/fellini.png)" },
        };
    } else if (theme === "agave") {
        return {
            splashPage: { "--background": "url(/assets/images/agave.png) no-repeat center center / cover" },
        };
    } else if (theme === "villanichesola") {
        return {
            splashPage: { "--background": "url(/assets/images/villa_nichesola.png) no-repeat center" },
        };
    } else if (theme === "kubic") {
        return {
            splashPage: { "--background": "url(/assets/images/kubic.png) no-repeat center" },
        };
    } else if (theme === "allright") {
        return {
            splashPage: { "--background": "url(/assets/images/allright.png) no-repeat center center / cover" },
            menupage: { "--background": "url(/assets/images/allright_1.png) no-repeat center center / cover" },
        };
    } else if (theme === "santo") {
        return {
            splashPage: { "--background": "url(/assets/images/santo.png) no-repeat center center / cover" },
            menupage: { "--background": "url(/assets/images/santo.png) no-repeat center center / cover" },
        };
    } else if (theme === "redbull") {
        return {
            splashPage: { "--background": "#20447A" },
            menupage: { "--background": "#20447A" },
        };
    } else if (theme === "sanpellegrino") {
        return {
            splashPage: { "--background": "#002B5C" },
            menupage: { "--background": "#002B5C" },
        };
    } else if (theme === "atlanta") {
        return {
            splashPage: { "--background": "#1DABE3" },
            menupage: { "--background": "#1DABE3" },
        };
    } else if (theme === "ultrabrasserie") {
        return {
            splashPage: { "--background": "url('/assets/ultrabrasserie/splash.png') 0 0/100% 100% no-repeat #FAF7F0" },
            menupage: { "--background": "url('/assets/ultrabrasserie/menu.png') 0 0/100% 100% no-repeat #FAF7F0" },
        };
    } else if (theme === "botanico") {
        return {
            splashPage: { "--background": "#005842" },
            menupage: { "--background": "#005842" },
        };
    } else if (theme === "zigozago") {
        return {
            splashPage: { "--background": "#eae2d7" },
            menupage: { "--background": "#eae2d7" },
        };
    } else if (theme === "bluenote") {
        return {
            splashPage: { "--background": "url('/assets/bluenote/background_splash.png') no-repeat center 180% / 100% #0E1E2C" },
            menupage: { "--background": "url('/assets/bluenote/background_splash.png') no-repeat center 180% / 100% #0E1E2C" },
        };
    } else if (theme === "almaluna") {
        return {
            splashPage: { "--background": "url('/assets/images/almaluna.png') no-repeat center 100% / 100%" }
        };
    } else if (theme === "gatsby") {
        return {
            splashPage: { "--background": "url('/assets/images/gatsby.png') no-repeat center 100% / 100%" }
        };
    } else if (theme === "primo") {
        return {
            splashPage: { "--background": "url('/assets/images/primo.png') no-repeat center 100% / 100%" }
        };
    } else if (theme === "settebello") {
        return {
            splashPage: { "--background": "url('/assets/images/settebello.png') no-repeat center 100% / 120%" }
        };
    } else if (theme === "kof") {
        return {
            splashPage: { "--background": "url('/assets/images/kof.png') no-repeat center 100% / 100%" }
        };
    } else if (theme === "mezzospirito") {
        return {
            splashPage: { "--background": "rgb(27, 63, 54)" },
            menupage: { "--background": "rgb(27, 63, 54)" }
        };
    } else if (theme === "venere") {
        return {
            splashPage: { "--background": "url('/assets/images/venere.png') no-repeat center 100% / 120%" }
        };
    } else if (theme === "belliner") {
        return {
            splashPage: { "--background": "url('/assets/images/belliner.png') no-repeat center 100% / 120%" }
        }
    } else if (theme === "krudo") {
        return {
            splashPage: { "--background": "#001d37" },
            menupage: { "--background": "#001d37" }
        }
    } else if (theme === "tascaro") {
        return {
            splashPage: { "--background": "#bab5aa" },
            menupage: { "--background": "#bab5aa" }
        }
    } else if (theme === "rossocardinale") {
        return {
            splashPage: { "--background": "#1c1c1c" },
            menupage: { "--background": "#1c1c1c" }
        }
    } else {
        return {
            menupage: null
        }
    }
}

function getTheme(id, setterPaletteState) {
    let stringId = String(id); // id should be a number but for extra flexibility I'll treat it as a string (string to string is ok)
    if (stringId === "9440" || stringId === "10158") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("motueka");
        setterPaletteState("motueka")
    } else if (stringId === "6433") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("camparino");
        setterPaletteState("camparino")
    } else if (stringId === "9506") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("voglia_argentina");
        setterPaletteState("voglia_argentina")
    } else if (stringId === "9207") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("morgana");
        setterPaletteState("morgana")
    } else if (stringId === "9521") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("il-cacciatore");
        setterPaletteState("il-cacciatore");
    } else if (stringId === "9520") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("pane-vino");
        setterPaletteState("pane-vino");
    } else if (stringId === "9522") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("mela-oro");
        setterPaletteState("mela-oro");
    } else if (stringId === "9540") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("hotel-dolomiti");
        setterPaletteState("hotel-dolomiti");
    } else if (stringId === "9564") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("mcdonald");
        setterPaletteState("mcdonald");
    } else if (stringId === "9570") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("mars");
        setterPaletteState("mars");
    } else if (stringId === "9616") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("adr");
        setterPaletteState("adr");
    } else if (stringId === "9638") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("pokeisland");
        setterPaletteState("pokeisland");
    } else if (stringId === "6474") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("radetzky");
        setterPaletteState("radetzky");
    } else if (stringId === "9889" || stringId === "9888" || stringId === "9887" || stringId === "9886" || stringId === "9885" || stringId === "9884" || stringId === "9883" || stringId === "9882" || stringId === "9881" || stringId === "9880" || stringId === "9879" || stringId === "10104" || stringId === "10296" || stringId === "13091") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("ten");
        setterPaletteState("ten");
    } else if (stringId === "11945") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("montaditos");
        setterPaletteState("montaditos");
    } else if (stringId === "9690" || stringId === "12045") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("barriobotanico");
        setterPaletteState("barriobotanico");
    } else if (stringId === "13025") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("capannina");
        setterPaletteState("capannina");
    } else if (stringId === "6568") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("vanquish");
        setterPaletteState("vanquish");
    } else if (stringId === "13668" || stringId === "13921") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("letorri");
        setterPaletteState("letorri");
    } else if (stringId === "6619") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("fellini");
        setterPaletteState("fellini");
    } else if (stringId === "10182") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("agave");
        setterPaletteState("agave");
    } else if (stringId === "14164") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("vanvitelli");
        setterPaletteState("vanvitelli");
    } else if (stringId === "14711") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("s_pellegrino");
        setterPaletteState("s_pellegrino");
    } else if (stringId === "8268") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("giappugliese");
        setterPaletteState("giappugliese");
    } else if (stringId === "6757") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("villanichesola");
        setterPaletteState("villanichesola");
    } else if (stringId === "6684") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("kubic");
        setterPaletteState("kubic");
    } else if (stringId === "8814") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("allright");
        setterPaletteState("allright");
    } else if (stringId === "14848") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("santo");
        setterPaletteState("santo");
    } else if (stringId === "6870") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("atlanta");
        setterPaletteState("atlanta");
    } else if (stringId === "15209") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("botanico");
        setterPaletteState("botanico");
    } else if (stringId === "15210") {
        let element = document.getElementsByTagName("body");
        element[0].classList.add("zigozago");
        setterPaletteState("zigozago");
    } else if (String(id) === "14926") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("ultrabrasserie");
        setterPaletteState("ultrabrasserie");
    } else if (String(id) === "9086") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("bluenote");
        setterPaletteState("bluenote");
    } if (id == "7123") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("almaluna");
        setterPaletteState("almaluna");
    } if (id == "7122") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("gatsby");
        setterPaletteState("gatsby");
    } if (id == "15000") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("primo");
        setterPaletteState("primo");
    } if (id == "15151") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("settebello");
        setterPaletteState("settebello");
    } if (id == "15149") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("kof");
        setterPaletteState("kof");
    } if (id == "15211") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("mezzospirito");
        setterPaletteState("mezzospirito");
    } if (id == "15381") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("fattoriegarofalo");
        setterPaletteState("fattoriegarofalo");
    } if (id == "6708") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("venere");
        setterPaletteState("venere");
    } if (id == "15374") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("belliner");
        setterPaletteState("belliner");
    } if (id == "280") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("ilovepoke");
        setterPaletteState("ilovepoke");
    } if (id == "7105") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("krudo");
        setterPaletteState("krudo");
    } if (id == "15711") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("tascaro");
        setterPaletteState("tascaro");
    } if (id == "10174") {
        var element = document.getElementsByTagName("body");
        element[0].classList.add("rossocardinale");
        setterPaletteState("rossocardinale");
    } else {
        console.warn(`There's no specific theme for id: ${id}`);
    }
}

function getCustomElement(id, element, t) {
    let stringId = String(id);
    if (element === "splash") {
        if (stringId === "9440" || stringId === "10158") {
            return "Drinklist";
        } else {
            return t("splash.button_1");
        }
    }
    if (element === "newtabs") {
        if (stringId === "9440" || stringId === "10158") {
            return "Drinklist";
        } else if (stringId === "9540") {
            return "BEVANDE";
        } else {
            return t("tab.button_2");
        }
    }
    if (element === "preferreddefaultscreen") {
        if (stringId === "9440" || stringId === "10158") {
            return "Premi sull'icona della stella per aggiungere un drink a questa lista. Ritroverai tutti i drink salvati in questa sezione, potendo scegliere tra i tuoi preferiti nel momento della creazione dell'ordine. Premi nuovamente sulla stella per rimuovere un preferito da questa lista.";
        } else {
            return t("preferreddefaultscreen.p_2");
        }
    }
}

const theme = {
    getThemeIonComponents,
    getTheme,
    getCustomElement,
};
export default theme;

import axios from 'axios';
import formats from '../utils/formats';

//const ENDPOINT = "https://api-ristoratori.mycia.it/v1/restaurants/lang/:lang/id/:id";
//export const ENDPOINT = "https://api-ristoratori.mycia.it/restaurants/v1/?ris_id=:id";
export const ENDPOINT = process.env.REACT_APP_ENDPOINT_RESTAURANTS + "?id=:id&lang=:lang&consumetime=:consumetime";
export const TOKENENDPOINT = process.env.REACT_APP_ENDPOINT_RESTAURANTS + "?token=:token&lang=:lang";
export const SEARCHENDPOINT = process.env.REACT_APP_ENDPOINT + "/v2/courses/?ris_id=:id&lang=:lang&search=:searchstring&consumetime=:consumetime";
export const FILTERS_ENDPOINT = process.env.REACT_APP_ENDPOINT + "/v2/courses/all?ris_id=:id&allergieprincipali=:allergies&nongraditi=:foods&diete=:diets&consumetime=:consumetime";
export const USERS_ENDPOINT = process.env.REACT_APP_ENDPOINT + "/v2/courses/all?ris_id=:id&users=:token&consumetime=:consumetime";
export const BOOKINGENDPOINT = "https://orders.mycontactlessmenu.mycia.it/api/events/booking";
export const REVIEWSENDPOINT = process.env.REACT_APP_ENDPOINT_V3 + "/restaurants/:token/reviews";
export const USERREVIEWSENDPOINT = process.env.REACT_APP_ENDPOINT_V3 + "/restaurants/:token/user_reviews";

const credentials = {
    username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
    password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
}

async function doGet(lang, id) {
    const isId = /^\d{1,5}$/.test(id);
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;
    let response;

    if (isId) {
        response = await axios.get(formats.formatUrl(ENDPOINT, { "lang": lang, "id": id, "consumetime": consumetime }), { auth: credentials });
    } else {
        response = await axios.get(formats.formatUrl(TOKENENDPOINT, { "lang": lang, "token": id }), { auth: credentials });
    }

    return response && response.data;
}

async function doGetSearch(lang, id, searchString) {
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;
    let response = await axios.get(formats.formatUrl(SEARCHENDPOINT, { "lang": lang, "id": id, "searchstring": searchString, "consumetime": consumetime }));

    return response && response.data;
}

async function doGetFilteredDishes(lang, id, allergies, foods, diets) {
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;

    // Return from all courses in a single array
    if (typeof allergies === "undefined" || allergies === null) {
        allergies = "";
    }
    if (typeof foods === "undefined" || foods === null) {
        foods = "";
    }
    if (typeof diets === "undefined" || diets === null) {
        diets = "";
    }

    let response = await axios.get(formats.formatUrl(FILTERS_ENDPOINT, { "lang": lang, "id": id, "allergies": allergies, "foods": foods, "diets": diets, "consumetime": consumetime }));

    return response && response.data;
}

function doGetManualFilter(products, allFilteredDishes) {
    // Do manual filter (es. return items that Metide hide) between searched dishes and filtered dishes),
    // add manual user_suggestion and icon
    // and sorting array to put "non adatto alle tue esigenze" after other items

    let aux = products;
    let trovato = false;
    let sort_index = 0;
    for (let i = 0; i < products.length; i++) {
        for (let j = 0; j < allFilteredDishes.dishes.length && !trovato; j++) {
            if (String(products[i].id) === String(allFilteredDishes.dishes[j].id)) {
                trovato = true;
                aux[i] = allFilteredDishes.dishes[j];
                aux[i].sort_index = 0;
            }
        }
        if (!trovato) {
            aux[i].user_suggestion = "Il prodotto non è adatto alle tue esigenze";
            aux[i].other_user_suggestion = "Il prodotto non è adatto alle tue esigenze";
            aux[i].icon = "images/icons/menu/ic_menu_no_icon.png";
            aux[i].sort_index = sort_index;
            sort_index++;
        }
        trovato = false;
    }
    aux.sort((a, b) => {
        let keyA = a.sort_index;
        let keyB = b.sort_index;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    });

    return aux;
}

async function doPostBooking(payload) {
    let data = {
        "customer": payload.customer,
        "note": payload.note,
        "phone": payload.phone,
        "reserved_at": payload.reserved_at,
        "reserved_time": payload.reserved_time,
        "seats": payload.seats,
        "token": payload.token,
    };

    if (payload.order) {
        data["order"] = payload.order;
    }

    try {
        let config = {
            method: 'post',
            url: BOOKINGENDPOINT,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        let response = await axios(config);

        return response.data;
    } catch (err) {
        console.log(err);
    }
}

async function doGetReviews(token) {
    let response = await axios.get(formats.formatUrl(REVIEWSENDPOINT, { "token": token }), { auth: credentials });
    return response && response.data;
}

async function doPostReview(payload) {

    let data = {
        preferred: payload.preferred.map(item => item.pia_id),
        ordered: payload.ordered.map(item => item.pia_id)
    };

    try {
        let config = {
            method: 'post',
            url: formats.formatUrl(REVIEWSENDPOINT, { "token": payload.token }),
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        };
        await axios(config);

        return "ok";
    } catch (err) {
        console.log(err);
    }
}

async function doPostUserReview(payload) {
    let data = {
        "user_id": 152620, //hardcoded account (pwd: reviews account)
        "rating": payload.rating,
        "title": "Recensione MyContactlessMenu",
        "text": payload.text,
    };

    try {
        let config = {
            method: 'post',
            url: formats.formatUrl(USERREVIEWSENDPOINT, { token: payload.token }),
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        };
        await axios(config);

        return "ok";
    } catch (err) {
        console.log(err);
    }
}

const restaurantClient = {
    doGet,
    doGetSearch,
    doGetFilteredDishes,
    doGetManualFilter,
    doPostBooking,
    doGetReviews,
    doPostReview,
    doPostUserReview,
};
export default restaurantClient;

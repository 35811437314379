import axios from 'axios';
import dl from "../utils/device-language";
import qs from 'qs';
import formats from '../utils/formats';

export const ENDPOINT = "https://api-ristoratori.mycia.it/statistics/v1/";
export const NEW_ANALYTICS = "https://loggermycia.sdch.develondigital.com/v1/logs/webapp";

var data = {
    "action": "",
    "attributes": [process.env.REACT_APP_NAMESPACE],
    "domain": window.location.origin,
    "lang": dl.getDeviceLanguage(),
    "metadata": {},
    "referrer": window.document.referrer,
    "start": Date.now(),
    "url": window.location.href,
    "user_agent": navigator.userAgent,
};

async function trackData(id, action, metadata) {
    data.action = action;
    data.metadata = metadata ?? Object.assign({}, metadata);

    if (
        "production" === process.env.NODE_ENV &&
        "test" !== process.env.REACT_APP_NAMESPACE
    ) {
        try {
            let formdata = new FormData();
            formdata.append("ris_id", id);
            formdata.append("text", JSON.stringify(data));

            return await axios.post(ENDPOINT, formdata);
        } catch (err) {
            console.log(err);
        }
    } else {
        console.log(data);
    }

    return data;
}

async function buildData(id, token, action, metadata, hasOrders) {
    var dataV2 = {
        "data": {
            "type": "webapplog",
            "attributes": {
                "ris_id": id,
                "token": token,
                "action": action,
                "payload": {
                    "action": action,
                    "attributes": [process.env.REACT_APP_NAMESPACE, hasOrders ? "o&p" : "mcm"],
                    "domain": window.location.origin,
                    "lang": dl.getDeviceLanguage(),
                    "metadata": metadata ?? Object.assign({}, metadata),
                    "referrer": window.document.referrer,
                    "start": Date.now(),
                    "url": window.location.href,
                    "user_agent": navigator.userAgent
                }
            }
        }
    }

    if (
        "production" === process.env.NODE_ENV &&
        "test" !== process.env.REACT_APP_NAMESPACE
    ) {
        try {
            dataV2.data.attributes.payload = JSON.stringify(dataV2.data.attributes.payload);

            return await axios.post(NEW_ANALYTICS, qs.stringify(dataV2));
        } catch (err) {
            console.log(err);
        }
    } else {
        console.log({ dataV2 });
    }

    return dataV2;
}

async function checkSession(id, token, action, metadata, hasOrders) {
    const now = Date.now();

    let lastSession = window.localStorage.getItem("__user_session__");

    if (lastSession) {
        lastSession = JSON.parse(lastSession);

        if (Math.abs(lastSession.time - now) <= 3600000) {
            window.localStorage.setItem("__user_session__", JSON.stringify({ token: lastSession.token, time: now }));
            return;
        }
    }

    try {
        window.localStorage.setItem("__user_session__", JSON.stringify({ token: formats.generateRandomID(""), time: now }));
        window.localStorage.removeItem("__user_has_reviewed__");

        return await buildData(id, token, action, metadata, hasOrders);
    } catch (e) {
        return console.log(e);
    }
}

async function trackDataV2(id, token, action, metadata, hasOrders) {
    await checkSession(id, token, action, metadata, hasOrders);

    buildData(id, token, action, metadata, hasOrders);
}

export default trackData;
export { trackDataV2 };

import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { useParams } from 'react-router';
import Bucket from './cart/Bucket';
import DeliveryPopupModalTrigger from './modals/DeliveryPopupModalTrigger';
import LanguageSelector from './LanguageSelector';
import assets from '../utils/assets';
import features from '../utils/features';
import formats from '../utils/formats';
import sharer from '../utils/sharer';
import { people, shareOutline, personOutline } from 'ionicons/icons';
import TimeDiscountModalTrigger from './modals/TimeDiscountModalTrigger';
import TabRouter from './UI/TabsRouter';
import SpoilerButton from './UI/SpoilerButton';
import SubscriptionModalTrigger from './modals/SubscriptionModalTrigger';
import CiaLoginModalTrigger from './modals/CiaLoginModalTrigger';
import CallRestaurantModalTrigger from './modals/CallRestaurantModalTrigger';
import BucketHeader from './cart/BucketHeader';
import LoginSuggestion from "./UI/LoginSuggestion";
import { Link } from "react-router-dom";
import { useUser } from "../utils/contexts/user-context";
import FilterToggle from "./UI/FilterToggle";

function List(props) {
    const { restaurant } = props;

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showCiaLoginModal, setShowCiaLoginModal] = useState(false);

    const { user } = useUser();
    const { type, id } = useParams();
    const { t, i18n } = useTranslation();

    useLayoutEffect(() => {
        let storage = window.localStorage.getItem("has_subscription");
        let obj = [{ id: "" }];
        if (storage) {
            obj = JSON.parse(storage);
        }
        if (obj.filter(a => String(a.id) === String(id)).length > 0) {
            setShowSubscriptionModal(false);
        }
    }, [id])

    return (
        <div className="relative">
            <BucketHeader type={type} id={id} />

            <div>
                {"mycia" !== process.env.REACT_APP_NAMESPACE && "morgana" !== process.env.REACT_APP_NAMESPACE && "redcafe" !== process.env.REACT_APP_NAMESPACE ?
                    <div className="restaurant-image w-full p-4 z-20">
                        <img src={assets.assetsLogoPath(restaurant.id)} alt="test" />
                    </div> :
                    <img src={restaurant.image_path} alt={t("menu.img_1")} className="w-full" />}
            </div>

            <div className="list relative -mt-4 py-2 rounded-t-lg bg-white">
                <header className="px-4 pb-[1px] border-b border-b-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="pr-2">
                            <h1 className="font-medium text-3xl">{restaurant.name}</h1>
                        </div>
                        <div className="flex items-center space-x-2">
                            {/* <div>
                                <a target="_blank" rel="noopener noreferrer" href="url to tip service" className="block">
                                    <img alt="tip service" className="w-10 h-10 bg-[#93E6A2] rounded-full" src="/assets/images/tip.png" />
                                </a>
                            </div> */}
                            {features.toggler().includeLanguageSelector() ?
                                <LanguageSelector onMenu={true} /> :
                                <div>
                                    <button onClick={() => (sharer.shareContent(`${type}/q/${id}`, restaurant.name, t("menu.button_1")))} className="button-native share w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-black">
                                        <IonIcon icon={shareOutline} size="medium" className="mb-1 text-xl"></IonIcon>
                                    </button>
                                </div>}
                            {restaurant.has_phonecall ? restaurant.phone_number && type !== "r" &&
                                <div className="mx-2">
                                    <CallRestaurantModalTrigger restaurant={restaurant} />
                                </div>
                                :
                                null
                            }
                            {restaurant.has_newsletter ?
                                showSubscriptionModal ?
                                    <button className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-black" onClick={() => setShowModal(true)}>
                                        <IonIcon icon={people} />
                                    </button>
                                    :
                                    null
                                :
                                null
                            }
                            {restaurant.has_login && "it" === i18n.language ?
                                user ?
                                    <Link to={`/profile?type=${type}&token=${id}`} className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-white bg-mycia-red">
                                        <IonIcon icon={personOutline} />
                                    </Link> :
                                    <a href={formats.loginUrl(type, id)}
                                        className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-white bg-mycia-red">
                                        <IonIcon icon={personOutline} />
                                    </a> :
                                null}
                        </div>
                    </div>

                    <SpoilerButton content={restaurant.menu_description} />

                    {formats.shouldDisplayRestaurantCoverCharge(restaurant, type) ?
                        <p className="mt-2 font-semibold">{t("menu.p_1")}: {formats.formatPrice(restaurant.cover_charge)}</p> : null}

                    {restaurant.has_login && "it" === i18n.language ?
                        user ?
                            <FilterToggle type={type} id={id} /> :
                            <LoginSuggestion type={type} id={id} /> :
                        null}

                </header>

                <TabRouter restaurant={restaurant} hasOrders={true} />

                <Bucket />

                {/* *** Various popup *** */}

                {restaurant.has_newsletter ? showSubscriptionModal ? <SubscriptionModalTrigger showModal={showModal} setShowModal={setShowModal} /> : <DeliveryPopupModalTrigger /> : <DeliveryPopupModalTrigger />}
                {features.toggler().includeTimeDiscount() ? <TimeDiscountModalTrigger /> : null}
                {restaurant.has_login && "it" === i18n.language ? !user ? <CiaLoginModalTrigger showModal={showCiaLoginModal} setShowModal={setShowCiaLoginModal} /> : null : null}
            </div>
        </div>
    );
}

export default List;

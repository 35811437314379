import React from 'react';
import PreferredButton from '../UI/PreferredButton';
import formats from '../../utils/formats';

export default function ReviewItem(props) {
    const { dish } = props;

    return (
        <div className="review-item p-2">
            <div className="flex rounded-lg shadow bg-white">
                <div className="flex-1 py-2 px-4">
                    <div className="h-full flex flex-col justify-between">
                        <div>
                            <p className="flex items-center font-semibold">{formats.capitalize(dish.name)}</p>
                            <p className="mt-2 text-sm" style={{ whiteSpace: "pre-wrap" }}>
                                {dish.description}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-end py-2 pr-4 text-base">
                    <PreferredButton item={dish} beverage={false} />
                    <p className="mt-2 font-bold">{dish.price} €</p>

                    {(typeof dish.image_path === "string" && dish.image_path !== "") ?
                        <img src={dish.image_path} className="object-cover w-26 h-26 my-3 rounded-lg border border-grey-300" alt="dish" /> :
                        null}
                </div>
            </div>
        </div>
    );
}

/**
 * helper function for dealing with the array case. It adds the category in the array of categories if not present (or finds it if present) and then returns it
 * @param {({name: String}[]|{id: Number, name: String, original_name: String, weight: Number}[])} catArray - an array of categories with different properties
 * @param {{id: Number, name: String, weight: Number}} subCategory - category to be added into the array or to be found
 * @returns the newly created category (or found category)
 */
function manageArrayEntry(catArray, subCategory) {
    let idx = catArray.findIndex((elem) => elem.tree_name === subCategory.tree_name);
    if (idx === -1) {
        catArray.push(subCategory);
        return catArray[catArray.length - 1];
    } else {
        return catArray[idx];
    }
}

/**
 * add the category if not present (or finds it if present) and then returns it
 * @param {(Object|Object[])} category - an array representing a set of categories, or an object representing a specific category
 * @param {Object} subCategory - the new category to be added or found in the array or the object
 * @returns the newly created category (or found category)
 */
function addCategory(category, subCategory) {
    if (Array.isArray(category)) {
        return manageArrayEntry(category, subCategory);
    } else if (typeof category === 'object' && category !== null && category.hasOwnProperty('nested')) {
        return manageArrayEntry(category.nested, subCategory);
    } else if (typeof category === 'object' && category !== null) {
        category.nested = [
            subCategory,
        ];
        return category.nested[category.nested.length - 1];
    }
}

/**
 * recursive function that given an array of categories or a category object is going to build the nested categories defined in the path array
 * @param {(Object|Object[])} category - an array of categories (initially empty) or a specific category object
 * @param {String[]} path - array representing a list of nested categories
 * @param {{id: Number, name: String, original_name: String, weight: Number}} apiEntry - flat category object obtained from the api
 * @param {Number} depth - nesting depth
 * @returns the categories nested exactly as the path is defined
 */
function buildPath(category, path, apiEntry, depth) {
    if (path.length > 0) {
        let subCategory = addCategory(category, { ...apiEntry, name: apiEntry.name.split(" /-/ ")[depth], tree_name: path[0], depth: depth });
        depth += 1;
        buildPath(subCategory, path.slice(1), apiEntry, depth);
    }
}

/**
 * given an array of flat categories, it's going to arrange them into a nested structure of sub categories
 * @param {{id: Number, name: String, original_name: String, weight: Number}[]} menu - flat array of menu categories, as defined in the API endpoint
 * @returns nested structure of menu subcategories
 */
function buildCategoriesTree(menu) {
    let categoriesTree = [];

    menu.sort((category1, category2) => category2.weight - category1.weight);
    menu.forEach(category => {
        buildPath(categoriesTree, category.original_name.split(" /-/ "), category, 0);
    });

    return categoriesTree;
}

// let tree = buildCategoriesTree(courseEng);

// console.dir(tree, { depth: null });

const beveragesCategoriesBuilder = {
    buildCategoriesTree,
};
export default beveragesCategoriesBuilder;
